<template>
    <div class="view-app">
        <div class="mt-lg-5 h-100">
            <div class="col-12 col-md-11 col-lg-10 mx-auto h-100">
                <div v-if="loading" class="d-flex justify-content-center align-items-center h-100 bg-secondary">
                    <loading size="60" color="primary" />
                </div>
                <template v-else>
                    <!--<div class="card p-4" v-if="!showPostPaymentView">-->
                    <div class="card p-4">
                        <template v-if="!showPostPaymentView">
                            <div class="d-flex">
                                <btn
                                    @click="
                                        $router.push({
                                            name: 'Dashboard',
                                        })
                                    "
                                    app
                                    color="info"
                                >
                                    <i class="fas fa-caret-left mr-1" />
                                    Home
                                </btn>
                            </div>
                            <h1 class="mb-9 text-center mt-0">Payment</h1>
                            <checkout-base
                                ref="checkoutBase"
                                @payment="postPayment"
                                :payment-request-data="paymentRequestData"
                                :payment-request-context="
                                    paymentRequestContext()
                                "
                            >
                            </checkout-base>
                            <div class="mt-3 d-flex justify-content-end">
                                <next-button @click="makePayment()" />
                            </div>
                        </template>
                        <template v-else>
                            <h1 class="font-weight text-center">
                                Thank you for your order
                            </h1>
                            <p class="lead text-center mb-7">
                                Your forms are being prepared. You will be
                                contacted when they are ready to go.
                            </p>
                            <h2 class="text-center">Your Purchases</h2>
                            <div class="mx-auto w-lg-50 w-md-75 w-100">
                                <div
                                    class="mb-3"
                                    v-for="p in fPayments"
                                    :key="p.id"
                                >
                                    <div class="mb-2 px-2">
                                        <div
                                            class="d-flex justify-content-between mb-2"
                                        >
                                            <h3 class="mb-0">
                                                {{ p.name }}
                                            </h3>
                                            <h3 class="font-weight-bold mb-0">
                                                <template>
                                                    {{
                                                        formatDollarAmount(
                                                            p.amount,
                                                        )
                                                    }}
                                                </template>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-center">
                                <btn
                                    app
                                    @click="
                                        $router.push({
                                            name: 'Dashboard',
                                        })
                                    "
                                    class="mt-5"
                                >
                                    <i class="fas fa-home mr-1" />
                                    Home
                                </btn>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import CheckoutBase from '@/components/utilities/checkout_base';
import submitErrorLog from '@/services/error-logs';
import BackButton from '@/components/interview/back-button';
import NextButton from '@/components/interview/next-button';

export default {
    components: {
        CheckoutBase,
        BackButton,
        NextButton,
    },
    methods: {
        paymentRequestContext() {
            return {
                payment_context: 'extra_payments',
                year: this.$route.params.year,
            };
        },
        postPayment() {
            for (var payment of this.paymentRequestData.items) {
                this.fPayments.push(payment);
            }
            this.showPostPaymentView = true;
        },
        makePayment() {
            if (!this.$refs.checkoutBase.isValid()) {
                return;
            }
            this.$refs.checkoutBase.makePayment().then((paymentSuccess) => {
                if (paymentSuccess) {
                    this.postPayment();
                }
            });
        },
    },
    data() {
        return {
            paymentRequestData: {},
            loading: true,
            showCheckout: false,
            fPayments: [],
            showPostPaymentView: false,
        };
    },
    created() {
        this.$store.getters.client
            .get('/extra-payments/', {
                params: { year: this.$route.params.year },
            })
            .then((response) => {
                this.paymentRequestData = response.data;
                if (
                    !this.paymentRequestData.items.length &&
                    !this.paymentRequestData.f_payments.length
                ) {
                    this.$router.push({
                        name: 'Dashboard',
                    });
                    return;
                }
                this.fPayments = this.paymentRequestData.f_payments;
                if (
                    this.paymentRequestData.items &&
                    this.paymentRequestData.items.length
                ) {
                    this.showCheckout = true;
                } else {
                    this.showPostPaymentView = true;
                }

                this.loading = false;
            });
    },
};
</script>
